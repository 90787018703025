export default [
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import(/* webpackChunkName: "aboutus" */ "@/views/public/static/AboutUs"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/business/career-representatives",
    name: "CareerRepresentative",
    component: () => import(/* webpackChunkName: "careerrepresentative" */ "@/views/public/business/CareerRepresentative"),
    meta: {
      navbar: "biz"
    }
  },
  {
    path: "/business/partners",
    name: "BusinessPartners",
    component: () => import(/* webpackChunkName: "businesspartners" */ "@/views/public/business/BusinessPartners"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/education/i-am-a-student",
    name: "IAmStudent",
    component: () => import(/* webpackChunkName: "iamstudent" */ "@/views/public/education/IAmStudent"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/education/teachers-and-parents",
    name: "TeachersParents",
    component: () => import(/* webpackChunkName: "teachersparents" */ "@/views/public/education/TeachersParents"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: () => import(/* webpackChunkName: "termsconditions" */ "@/views/public/static/TermsConditions"),
  },
]