import { createRouter, createWebHashHistory } from "vue-router"
import store from "@/store";
import HomeView from "../views/HomeView.vue"
import publicRoutes from "./modules/public"

const baseRoutes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  }
]

const routes = baseRoutes.concat(publicRoutes);

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else {
      return { top: 0 }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  /***
   * check navbar style
   */
  if (to.matched.some(record => record.meta.navbar)) {
    let style = to.meta.navbar;
    if (style === "basic") {
      style = ""
    }
    store.commit("theme/UpdTheme", {
      cat: "NavBar",
      value: style
    });
  }
  to.meta.previousRoute = from.path
  next()
})

export default router
