<template>
  <router-link to="/" class="brand w-nav-brand" :class="cssClass">
    <img
      class="brand-img"
      :class="invertImg"
      :src="imageSrc + '/logo-vertical.svg'"
      width="33"
      alt="vertical logo"
    >
  </router-link>
</template>

<script>
export default {
  name: "Brand",
  data() {
    return {
      cssClass: "",
      imageSrc: process.env.VUE_APP_CLOUDFRONT,
      prevScrollpos: window.pageYOffset
    }
  },
  computed: {
    invertImg() {
      return (this.$store.state.theme.NavBar === "biz") ? "invert" : ""
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      let currentScrollPos = window.pageYOffset;
      if (currentScrollPos < 10) {
        this.cssClass = "";
      } else {
        this.cssClass = "is-hide";
      }
      this.prevScrollpos = currentScrollPos;
    }
  }
}
</script>

<style scoped>
.w-nav-brand {
  bottom: auto;
  float: left;
  left: 20px;
  position: fixed;
  right: auto;
  text-decoration: none;
  top: 20px;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  will-change: transform;
}
.w-nav-brand.is-hide {
  transform: translate3d(-79.6328px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}
.w-nav-brand .brand-img.invert {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
</style>