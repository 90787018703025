import { createStore } from "vuex"
import show from "./modules/show"
import theme from "./modules/theme"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    show,
    theme
  }
})
