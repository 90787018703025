import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import { createMetaManager } from "vue-meta";
import "animate.css"
/* Add font awesome library */
import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faFacebookF, faInstagram, faLinkedin, faPinterest, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faArrowDown, faArrowRight, faArrowUp, faBan, faCircleInfo, faCopyright, faHandHoldingHand, faRotate } from "@fortawesome/free-solid-svg-icons"
library.add(faArrowDown, faArrowRight, faArrowUp, faBan, faCircleInfo, faCopyright, faFacebookF, faHandHoldingHand, faInstagram, faLinkedin, faPinterest, faRotate, faXTwitter, faYoutube)
dom.watch()

const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon)
app.use(store).use(router).use(createMetaManager()).mount("#app")
