<template>
  <metainfo>
    <template #title="{ content }">
      {{ content ? `${content} | ${metaTitle}` : `${metaTitle}` }}
    </template>
  </metainfo>
  <NavBar />
  <div class="public-image-section">
    <router-view v-slot="{ Component }">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <FooterView />
</template>

<script>
/***
 * vue components/libraries
 */
import { mapGetters } from "vuex";
import FooterView from "@/components/static/FooterView";
import NavBar from "@/components/static/NavBar";
/***
 * custom libraries
 */
import { useMeta } from "vue-meta";

export default {
  name: "App",
  components: {
    FooterView,
    NavBar
  },
  setup() {
    useMeta({
      title: "Welcome",
      htmlAttrs: { lang: 'en', amp: true },
      link: [
        { rel: "canonical", href: `${process.env.VUE_APP_BASEURL}` }
      ],
      meta: [{
        vmid: "description",
        name: "description",
        content: "Career Exploration and Training. Connecting K-12 Students' Talents, Interests & Aptitudes to the World of Work"
      }]
    })
  },
  data() {
    return {
      metaTitle: "Business and Education Alliance"
    }
  },
  computed: {
    ...mapGetters("show", ["isToggle"])
  }
}
</script>

<style lang="scss">
@import "bulma";
@import "~bulma-tooltip";
@import "@/assets/style/main.scss";
</style>
