<template>
  <nav class="navbar is-flex is-justfy-center top-navbar is-fixed-top" :class="hasTheme" role="navigation" aria-label="main navigation is-primary">
    <div class="navbar-brand">
      <BrandView />
      <a
        role="button"
        class="navbar-burger burger csr-ptr"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar"
        @click="toggleMenu"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div id="navbar" class="navbar-menu" :class="{'is-active': !isToggle}">
      <div class="navbar-end">
        <div class="navbar-item dropdown-education has-dropdown is-hoverable">
          <a class="navbar-link">
            Education
          </a>
          <div class="navbar-dropdown is-right">
            <div class="navbar-item is-no-underline is-hidden-touch">
              <BannerEdu />
            </div>
            <router-link class="navbar-item navbar-sublink close-nav" to="/education/i-am-a-student">
              I'm a Student
            </router-link>
            <router-link class="navbar-item navbar-sublink close-nav" to="/education/teachers-and-parents">
              Teachers &amp; Parents
            </router-link>
            <a class="navbar-item navbar-sublink close-nav" href="https://cclmarketplace.org/education/education-resources">
              Education Resources
            </a>
            <a class="navbar-item navbar-sublink close-nav is-hidden-desktop" href="https://cclmarketplace.org/register">
              Student/Educator Sign-Up
            </a>
            <div class="navbar-item is-no-underline is-hidden-touch mt-4">
              <a class="button is-dark navbar-sublink" href="https://cclmarketplace.org/register">
                Student/Educator Sign-Up
              </a>
            </div>
          </div>
        </div>
        <div class="navbar-item dropdown-business has-dropdown is-hoverable">
          <a class="navbar-link">
            Business
          </a>
          <div class="navbar-dropdown is-right">
            <div class="navbar-item is-no-underline is-hidden-touch">
              <BannerBiz />
            </div>
            <router-link class="navbar-item navbar-sublink close-nav" to="/business/career-representatives">
              Career Representative
            </router-link>
            <router-link class="navbar-item navbar-sublink close-nav" to="/business/partners">
              Partners
            </router-link>
            <a class="navbar-item navbar-sublink close-nav" href="https://cclmarketplace.org/business/business-resources">
              Business Resources
            </a>
            <a class="navbar-item navbar-sublink close-nav is-hidden-desktop" href="https://cclmarketplace.org/become-career-representative">
              Business Sign-Up
            </a>
            <div class="navbar-item is-no-underline is-hidden-touch mt-4">
              <a class="button is-business navbar-sublink" href="https://cclmarketplace.org/become-career-representative">
                Business Sign-Up
              </a>
            </div>
          </div>
        </div>
        <a class="navbar-item is-signin-link" title="Log In" href="https://cclmarketplace.org/login">
          Sign In
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import BannerBiz from "@/components/static/BannerBiz";
import BannerEdu from "@/components/static/BannerEdu";
import BrandView from "@/components/static/BrandView";


export default {
  name: "NavBar",
  components: {
    BannerBiz,
    BannerEdu,
    BrandView
  },
  data() {
    return {
      imageSrc: process.env.VUE_APP_CLOUDFRONT,
    }
  },
  computed: {
    ...mapGetters("show", ["isToggle"]),
    hasTheme() {
      let path = this.$store.state.theme.NavBar;
      let temp = "";
      switch(path) {
        case "stu":
          temp = "is-stu";
          break;
        case "biz":
          temp = "is-biz"
          break;
        default:
          temp = ""
      }
      return temp
    }
  },
  mounted() {
    this.closeNav();
    // Add a listener to close mobile navbar drop down menu
    document.getElementById("navbar")
      .querySelectorAll(".has-dropdown")
      .forEach(el => {
        el.addEventListener("click", () => {
          let menu = el.querySelector(".navbar-dropdown")
          menu.style.display = "none"
          setTimeout(() => {
            el.blur()
            // Reset the display property to its original state, so the menu can appear again next time
            menu.style.display = ""
          }, 200)
        })
      })
  },
  methods: {
    // automatically close navbar dropdown after click an link item
    closeNav() {
      const navBar = document.getElementById("navbar");
      if (navBar) {
        navBar
          .querySelectorAll(".close-nav")
          .forEach(el => {
            el.addEventListener("click", () => {
              setTimeout(function() {
                this.toggleMenu()
              }.bind(this), 10)
            })
          })
      }
    },
    // toggle navbar/side menu
    toggleMenu() {
      this.$store.commit("show/UpdShow", {
        cat: "sideMenu",
        value: !this.isToggle
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.logo {
  height: 28px;
}
.navbar, .navbar .navbar-item:hover, .navbar .navbar-item:focus {
  @extend .has-background-grey-default;
  color: $color-darker
}
.navbar.is-biz {
  background-color: $color-biz !important;
  .navbar-burger {
    color: white
  }
}
.navbar.is-stu, .navbar.is-stu .navbar-item:hover, .navbar.is-stu .navbar-item:focus {
  background-color: $color-student !important;
}
.navbar .navbar-item:hover, .navbar .navbar-item:focus {
  text-decoration: underline
}
.navbar {
  .navbar-item {
    .navbar-dropdown {
      .navbar-item {
        font-size: 24px;
      }
    }
  }
}
.navbar.top-navbar {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.is-flex-touch.is-desktop-toggle {
  display: none !important;
  justify-content: center;
  width: 3.25rem;
}
.navbar.has-shadow {
  background-color: #eee;
}
.navbar-item.navbar-internal-toggle {
  align-items: center
}
@media screen and (min-width: 1087px) {
  .is-flex-touch.is-desktop-toggle {
    display: flex !important;
  }
}
@media screen and (min-width: 1024px) {
  .navbar.is-biz .navbar-item, .navbar.is-biz .navbar-link {
    color: white
  }
  .navbar .navbar-item.has-dropdown:hover .navbar-link {
    color: $color-darker;
    background-color: $color-grey-default !important;
  }
  .navbar.is-biz .navbar-item.has-dropdown:hover .navbar-link {
    background-color: $color-biz !important;
  }
  .navbar.is-stu .navbar-item.has-dropdown:hover .navbar-link {
    background-color: $color-student !important;
  }
  .navbar {
    .navbar-dropdown {
      background-repeat: no-repeat;
      border-radius: 6px;
      -webkit-box-shadow: 0 1px 6px rgba(0,0,0,.24), 0 1px 8px rgba(0,0,0,.12);
      -ms-box-shadow: 0 1px 6px rgba(0,0,0,.24), 0 1px 8px rgba(0,0,0,.12);
      box-shadow: 0 1px 6px rgba(0,0,0,.24), 0 1px 8px rgba(0,0,0,.12);
    }
    .navbar-item {
      text-decoration: underline;
    }
    .navbar-sublink {
      font-size: 1.25rem
    }
    .navbar-item:hover, .navbar-item:focus {
      text-decoration: none
    }
  }
  .navbar .dropdown-business {
    .navbar-dropdown {
      background-color: $color-biz !important;
      border-top: 2px solid $color-biz
    }
    .navbar-item {
      color: #fff;
    }
    .navbar-item:hover, .navbar-item:focus {
      background-color: $color-biz !important;
    }
  }
  .navbar .dropdown-education {
    .navbar-dropdown {
      background-color: $color-student !important;
      border-top: 2px solid $color-student
    }
    .navbar-item {
      color: $color-darker;
    }
    .navbar-item:hover, .navbar-item:focus {
      background-color: $color-student !important;
    }
  }
}
@media screen and (max-width: 1023px) {
  .is-signin-link {
    font-size: 1.5rem
  }
  .navbar-burger {
    position: absolute;
    right: 0
  }
  .navbar-menu.is-active {
    margin-top: 50px;
    width: 100%;
    z-index: 10
  }
  .navbar {
    .navbar-item {
      .navbar-link {
        font-size: 24px;
        font-weight: 500
      }
    }
  }
}
</style>
