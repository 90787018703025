<template>
  <div class="main-wrapper">
    <!-- hero section -->
    <section class="section hero has-background-grey-default is-full-height is-relative pb-0">
      <div class="container hero">
        <div class="content-width-medium">
          <h1 class="hero-heading">
            C<span class="salt-span">A</span>RE<span class="salt-span">E</span>R
            <br>
            Expl<span class="salt-span">o</span>ration
            <br>
            &amp; Tr<span class="salt-span">a</span>ining.<br>
          </h1>
          <div class="stats-block is-hidden-tablet">
            <div class="stat-wrapper">
              <div class="div-block-3">
                <p class="stat-number">
                  {{ stats.amount }}
                </p>
              </div>
              <p class="stat-text ml-2 is-capitalized" v-html="sanitizeContent(stats.category)" />
            </div>
          </div>
          <p class="hero">
            Connecting K-12 students' <em>talents</em>, <em>interests</em> &amp; <em>aptitudes</em> to the world of work.
          </p>
          <div class="is-inline-block">
            <a class="button-main" data-anchor="intro-section" @click="scrollDown">
              <span class="is-justify-content-flex-start">Get to Know PPBEA</span>
              <span class="is-justify-content-flex-end">
                <font-awesome-icon class="fa-fw" icon="arrow-down" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="hero-img">
        <div class="hero-img-frame">
          <transition
            enter-active-class="animate__animated animate__bounceIn animate__delay-1s"
            leave-active-class="animate__animated animate__bounceOut"
          >
            <img
              :key="heroImg.count"
              :src="'/img/hero-img' + heroImg.count + '-800.jpeg'"
              class="hero-photo"
              width="876"
              loading="eager"
              :alt="heroImg.images[heroImg.count]"
            >
          </transition>
        </div>
        <div class="has-text-grey-darker has-text-right pr-2">Photo by <a href="https://unsplash.com/@jeswinthomas?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Jeswin Thomas</a> on <a href="https://unsplash.com/@jeswinthomas?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
        </div>
      </div>
      <img class="shape rect is-unselectable" src="@/assets/img/pattern-rect.svg" loading="lazy">
      <img class="shape circles is-unselectable" src="@/assets/img/pattern-circle.svg" loading="lazy">
      <img class="shape is-unselectable" src="@/assets/img/pattern-triangle.svg" loading="lazy">
      <div class="stats-block is-hidden-mobile">
        <div class="stat-wrapper">
          <div class="div-block-3">
            <p>
              {{ stats.amount }}
            </p>
          </div>
          <p class="stat-text is-capitalized ml-1" v-html="sanitizeContent(stats.category)" />
        </div>
      </div>
    </section>

    <!-- Intro Section -->
    <section id="intro-section" class="section intro-section is-relative px-0">
      <div class="container">
        <h2 class="intro-heading">
          What&#x27;s the next step<br>
          in your <span class="has-text-yellow">academic</span> to<br>
          ‍<span class="has-text-yellow">career</span> pathway?
        </h2>
      </div>
      <div class="students-block">
        <div class="student-block is-hidden-mobile is-unselectable">
          <img class="student-img" src="@/assets/img/student5.png" loading="eager" width="220" alt="Student holding books">
        </div>
        <div class="student-block is-unselectable">
          <img class="student-img" src="@/assets/img/student1.png" loading="eager" width="210" alt="Student holding books">
        </div>
        <div class="student-block is-unselectable">
          <img class="student-img" src="@/assets/img/student6.png" loading="eager" width="270" alt="Student holding books">
        </div>
        <div class="student-block is-unselectable">
          <img class="student-img" src="@/assets/img/student4.png" loading="eager" width="218" alt="Student holding books">
        </div>
        <div class="student-block is-unselectable">
          <img class="student-img" src="@/assets/img/student2.png" loading="eager" width="210" alt="Student holding books">
        </div>
        <div class="student-block is-hidden-mobile is-unselectable">
          <img class="student-img" src="@/assets/img/student3.png" loading="eager" width="192" alt="Student holding books">
        </div>
      </div>
      <div id="button-welcome" class="welcome" />
      <div class="mountain-separator" />
    </section>

    <!-- Workbased Section -->
    <section class="section work-based is-relative">
      <div class="container">
        <h2 class="stretch-heading">
          <span class="is-capitalized">The Career-Connected</span><br>
          <span class="salt-span">LEArning</span><br>
          M<span class="salt-span">a</span>rk<span class="salt-span">e</span>tpla<span class="salt-span">c</span>e
        </h2>
      </div>
      <div class="text-container">
        <h3 class="is-heading">Students</h3>
        <p class="is-size-3">
          Learn how you fit into the world of work by interacting with career representatives in the workplace!
        </p>
        <h3 class="is-heading">Career Representatives</h3>
        <p class="is-size-3">
          Learn how to share your career-connected experience with the next generation!
        </p>
      </div>
      <div class="top-left-figure">
        <img class="spot" src="@/assets/img/spot1.svg" loading="lazy" alt="Runner background">
        <img class="runner-img" src="@/assets/img/runner.svg" loading="eager" alt="Runner illustration">
      </div>
      <div class="bottom-right-figure">
        <img class="spot" src="@/assets/img/spot2.svg" loading="lazy" width="400">
        <img class="desk-img" src="@/assets/img/representative.svg" loading="eager" width="300" alt="Representative illustration">
      </div>
    </section>

    <!-- People -->
    <section class="section people">
      <div class="container people">
        <div class="columns">
          <div class="column">
            <h2 class="common-heading">
              F<span class="salt-span">o</span>r the<br>
              Comm<span class="salt-span">o</span>n<br>
              G<span class="salt-span">ood</span>
            </h2>
          </div>
          <div class="column is-flex-direction-column is-justify-content-flex-start">
            <img class="mountains" src="@/assets/img/mountains.svg" loading="eager" width="260">
            <p class="people-mission">
              PPBEA is on a mission to a more sustainable talent development model. Success relies on the time and talent of many individuals, engaged for the better of our community.
            </p>
          </div>
        </div>
        <p class="is-size-4 is-italic">
          People Involved:
        </p>
        <div class="people-grid">
          <div class="people-flex-block">
            <div class="people-tag">Students</div>
            <div class="line-block" />
          </div>
          <div class="people-flex-block is-flex-direction-column">
            <div class="people-flex-block">
              <div class="people-tag education">education</div>
              <div class="line-block filled" />
            </div>
            <ul role="list" class="people-list">
              <li class="people-item">Teachers</li>
              <li class="people-item">Education Staff</li>
              <li class="people-item">Parents/Guardians</li>
            </ul>
          </div>
          <div class="people-flex-block is-flex-direction-column">
            <div class="people-flex-block">
              <div class="line-block filled business" />
              <div class="people-tag business">business</div>
            </div>
            <ul role="list" class="people-list business">
              <li class="people-item business">Career Representatives</li>
              <li class="people-item business">Advisory Members</li>
              <li class="people-item business">Mentors</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Power of Experience -->
    <PowerOfExperience />

    <!-- Everyone Has A Story -->
    <section class="section story has-padding-large">
      <div class="container has-text-centered">
        <div class="hashtag">
          <span class="has-text-yellow">#</span>EveryoneHasAStory&trade;
        </div>
        <h3 class="story-title">
          Share your<br>Career Journey!
        </h3>
        <div class="columns story-content">
          <div class="column has-text-left is-size-4">
            <div class="content">
              <p>
                Each and every adult, no matter their occupation, took a different path to get to where they are today.
              </p>
              <p>
                Work with PPBEA to capture your unique career story. K-12 educators and students will have access to this content through the PPBEA MARKETPLACE to guide academic pathway planning.
              </p>
              <p>
                Once the recorded sessions are edited they can be easily accessed through the PPBEA MARKETPLACE by teachers and students for career exploration activities.
              </p>
            </div>
          </div>
          <div class="column is-1 is-hidden-mobile">
            &nbsp;
          </div>
          <div class="column has-text-left">
            <div class="story-list">
              <div class="s-list-item">
                <div class="tick-composition">
                  <img class="square" src="@/assets/img/tick-square.svg" loading="lazy">
                  <img class="tick" src="@/assets/img/tick-solo.svg" loading="lazy">
                </div>
                <div>Donate 75 minutes of your time to record your unique career story.</div>
              </div>
              <div class="s-list-item">
                <div class="tick-composition">
                  <img src="@/assets/img/tick-square.svg" loading="lazy" alt="" class="square">
                  <img src="@/assets/img/tick-solo.svg" loading="lazy" alt="" class="tick">
                </div>
                <p>Expect a casual organic but completely facilitated conversation.</p>
              </div>
              <div class="s-list-item">
                <div class="tick-composition">
                  <img src="@/assets/img/tick-square.svg" loading="lazy" class="square">
                  <img src="@/assets/img/tick-solo.svg" loading="lazy" class="tick">
                </div>
                <div>Questions to be covered are provided prior to session.</div>
              </div>
              <div class="s-list-item">
                <div class="tick-composition">
                  <img src="@/assets/img/tick-square.svg" loading="lazy" alt="" class="square">
                  <img src="@/assets/img/tick-solo.svg" loading="lazy" alt="" class="tick">
                </div>
                <div>Speak into the lives of the next generation.</div>
              </div>
            </div>
            <a class="button is-schedule-now" href="https://bob-ppbea.youcanbook.me" target="_blank">
              Schedule Now
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- Join Initiative -->
    <section class="section join has-padding-large">
      <div class="container has-text-centered">
        <div class="text-container">
          <h3>Join the PPBEA Initiative!</h3>
        </div>
        <div class="columns">
          <div class="column">
            <div class="dropdown-block education not-menu">
              <BannerEdu />
              <router-link class="banner-menu-link has-text-dark" to="/education/i-am-a-student">
                I'm a Student
              </router-link>
              <router-link class="banner-menu-link has-text-dark" to="/education/teachers-and-parents">
                Teachers &amp; Parents
              </router-link>
              <a
                href="https://cclmarketplace.org/education/education-resources"
                class="banner-menu-link has-text-dark"
                target="_blank"
              >
                Education Resources
              </a>
              <a class="button is-dark is-signup" href="https://cclmarketplace.org/register">
                Student/Educator Sign-Up
              </a>
            </div>
          </div>
          <div class="column">
            <div class="dropdown-block business not-menu">
              <BannerBiz />
              <div class="w-embed" />
              <router-link class="banner-menu-link has-text-white" to="/business/career-representatives">
                Career Representative
              </router-link>
              <a
                href="https://cclmarketplace.org/business/advisories"
                class="banner-menu-link has-text-white"
                target="_blank"
              >
                Advisories &amp; Partners
              </a>
              <a
                href="https://cclmarketplace.org/business/business-resources"
                class="banner-menu-link has-text-white"
                target="_blank"
              >
                Business Resources
              </a>
              <a
                href="https://cclmarketplace.org/become-career-representative"
                class="button is-business is-signup"
                target="_blank"
              >
                Business Sign-Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
/***
 * vue components
 */
import BannerBiz from "@/components/static/BannerBiz";
import BannerEdu from "@/components/static/BannerEdu"
import PowerOfExperience from "./public/home/PwrOfExp";
/***
 * custom libraries
 */
import DOMPurify from "dompurify"
import { ScrollRotate, scrollSmooth } from "@/utils/animate";

export default {
  name: "HomeView",
  components: {
    BannerBiz,
    BannerEdu,
    PowerOfExperience
  },
  data() {
    return {
      count: 0,
      heroImg: {
        count: 0,
        images: [
          "Student Writing",
          "Students Working Together",
          "Students Working on a Project",
          "Students Working on a Project"
        ]
      },
      items: {},
      stats: {
        iid: "",
        category: "known category",
        amount: 0,
        system: 0
      }
    }
  },
  created() {
    this.init();
    this.$store.commit("theme/UpdTheme", {cat: "NavBar", value: ""})
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    /***
     * initiate data fetch from the back end.
     */
    init() {
      const impact = {
        "errno": 0,
        "errmsg": {
          "0": {
            "iid": "int",
            "category": "student interactions",
            "amount": 16230,
            "system": 0
          },
          "1": {
            "iid": "opp",
            "category": "opportunities",
            "amount": 3452,
            "system": 1
          },
          "2": {
            "iid": "stu",
            "category": "student users",
            "amount": 9613,
            "system": 1
          }
        },
        "status": "good",
        "note": "community impact data",
        "count": 3
      }
      this.displayImpact(impact);
      this.rotatePhotos()
    },
    /***
     * display impact data
     */
    displayImpact(data) {
      window.setTimeout(function() {
        if (data.errmsg[this.count].category === "student interactions") {
          data.errmsg[this.count].category = "student<br>interactions"
        }
        this.stats = data.errmsg[this.count];
        this.count = (this.count + 1) % 3;
        this.displayImpact(data)
      }.bind(this), 3000);
    },
    /***
     * rotate welcome image
     */ 
    handleScroll() {
      ScrollRotate("button-welcome", 6)
    },
    /***
     * rotate photos in hero section
     */
    rotatePhotos() {
      window.setTimeout(function() {
        this.heroImg.count = (this.heroImg.count + 1) % 4;
        this.rotatePhotos()
      }.bind(this), 5000);
    },
    /***
     * sanitize v-html
     */
    sanitizeContent(content) {
      return DOMPurify.sanitize(content)
    },
    /***
     * animation scroll down
     */
    scrollDown(e) {
      scrollSmooth(e.currentTarget.dataset.anchor);
    }
  }
}
</script>

<style lang="scss" scoped>
.container.hero p {
  max-width: 450px;
}
.shape.rect {
  right: 30%;
  bottom: 36vw;
  width: 8.1vw;
}
@media screen and (min-width: 1280px) {
  .shape.rect {
    width: 7vw;
  }
  .student-img {
    min-height: 230px;
  }
}
@media (max-width: 479px) {
  .intro-heading {
    font-size: 13vw;
    -webkit-text-stroke: 1.2px black;
  }
  .student-block {
    margin-right: -16px;
    margin-left: -16px;
  }
}
@media (max-width: 600px) {
  .intro-heading {
    -webkit-text-stroke: 1.4px black;
  }
}
@media screen and (max-width: 991px) {
  .shape.rect {
    left: 7%;
    top: auto;
    right: auto;
    bottom: 38%;
    width: 11vw;
  }
}
</style>