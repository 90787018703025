const state = () => ({
  // bizEditor: false,
  // bizNewRep: false,
  // curTab: "",
  // formSchl: false,
  // message: false,
  // modal: false,
  // oppAdvFilter: false,
  sideMenu: true,
  // uploadLogo: false
})

const mutations = {
  // update expand sections
  UpdShow(state, data) {
    state[data.cat] = data.value;
  },
}

const getters = {
  isToggle: state => {
    return (state.sideMenu) ? "is-active" : ""
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
